// this function searches any arbitrary JSON structure
// for the values of "statusMsg" field and returns an array of the unique values
export function getLocationStatuses(data: unknown): string[] {
  const statuses = new Set<string>()

  function recurse(obj: unknown): void {
    if (Array.isArray(obj)) {
      // If obj is an array, recurse over each item
      for (const item of obj) {
        recurse(item)
      }
    } else if (obj !== null && typeof obj === "object") {
      // Explicitly check that obj is not null and is an object
      const objRecord = obj as Record<string, unknown>
      for (const key in objRecord) {
        if (Object.prototype.hasOwnProperty.call(objRecord, key)) {
          const value = objRecord[key]
          if (key === "statusMsg" && typeof value === "string") {
            const capitalizedStatus = value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()
            statuses.add(capitalizedStatus)
          } else {
            // Recurse into the value
            recurse(value)
          }
        }
      }
    }
    // If obj is neither an array nor an object, do nothing
  }

  recurse(data)

  return Array.from(statuses)
}
